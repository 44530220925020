<template>
  <router-link class="rating-region-card card" :to="{path: '/region-indicators/view-indicators', query: {year: year?.value, regionId: data.id}}">
    <div class="card__rating">{{ data.countryRating }}</div>
    <div class="card__body">
      <div class="card__body-left">
        <img :src="data?.fileSmallSrc" alt="" />
        <div class="card__body-left-title">
          <p>{{ data.name }}</p>
          <div class="circle" v-if="data?.federalDistrictShortName"></div>
          <span>{{ data?.federalDistrictShortName }}</span>
        </div>
      </div>
      <div class="card__body-center">
        <p>{{ $t('place-in-federal-district') }}</p>
        <span>{{ data.federaDistrictRating || 0 }}</span>
      </div>
      <div class="card__body-right">
        <div class="card__body-right-item">
          <p :class="{active: sortBy.value === 'E'}">{{ $t('environmental-component') }}</p>
          <span>{{ getEnvironmental }}</span>
        </div>
        <div class="card__body-right-item">
          <p :class="{active: sortBy.value === 'S'}">{{ $t('social-component') }}</p>
          <span>{{ getSocial }}</span>
        </div>
        <div class="card__body-right-item">
          <p :class="{active: sortBy.value === 'G'}">{{ $t('governance-component') }}</p>
          <span>{{ getGovernance }}</span>
        </div>
        <div class="card__body-right-item">
          <p :class="{active: sortBy.value === 'Total'}">{{ $t('total-points') }}</p>
          <span>{{ getFinal }}</span>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "RatingRegionCard",
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    year: {
      type: Object,
      default: () => {}
    },
    number: {
      type: [Number, String],
      default: 0
    },
    sortBy: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    getFinal () {
      let finalResult = this.data.rrTypes[0]?.value + this.data.rrTypes[1]?.value + (this.data.rrTypes[2]?.value ? this.data.rrTypes[2]?.value : 0)
      if (finalResult.toString().split('.')[1]?.length >= 5) {
        return finalResult.toFixed(6).replace('.', ',')
      } else {
        return +finalResult.toString().replace('.', ',') || 0
      }
    },

    getEnvironmental () {
      let data = ''
      this.data.rrTypes.forEach(el => {
        if(el.name === 'Экологические') {
          data = el.value ? this.getValue(el.value) : 0
        }
      })
      return data
      // this.data.rrTypes[0]?.value ? getValue(data.rrTypes[0].value) : 0
    },

    getSocial () {
      let data = ''
      this.data.rrTypes.forEach(el => {
        if(el.name === 'Социальные') {
          data = el.value ? this.getValue(el.value) : 0
        }
      })
      return data
      // this.data.rrTypes[1]?.value ? getValue(data.rrTypes[1].value) : 0
    },

    getGovernance () {
      let data = ''
      this.data.rrTypes.forEach(el => {
        if(el.name === 'Экономические') {
          data = el.value ? this.getValue(el.value) : 0
        } else {
          data = 0
        }
      })
      return data
      // this.data.rrTypes[2]?.value ? getValue(data.rrTypes[2].value) : 0
    }
  },

  methods: {
    getValue (val) {
      if (val.toString().split('.')[1]?.length >= 5) {
        return val.toFixed(6).replace('.', ',')
      } else {
        return val.toString().replace('.', ',') || 0
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  align-items: center;
  gap: 12px;

  @media(max-width: 800px) {
    gap: 5px;
  }

  &__rating {
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #CE2121;
    color: #FFFFFF;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 103.7%;
  }

  &__body {
    display: flex;
    align-items: center;
    gap: 35px;
    padding: 12px 20px 12px 13px;
    background-color: #FFFFFF;
    max-width: 965px;
    width: 100%;
    border-radius: 50px;
    fill: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.20);

    @media (max-width: 768px) {
      gap: 5px;
    }

    &-left {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 380px;

      @media (max-width: 1300px) {
        width: 280px;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid #CE2121;
      }

      &-title {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;

        p {
          color: #1B1A1F;
          font-family: 'Gotham Pro Medium';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          gap: 5px;
        }

        span {
          color: #1B1A1F;
          font-family: 'Gotham Pro Medium';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }

        .circle {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: black;
        }
      }
    }

    &-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      height: 44px;
      justify-content: space-between;

      p {
        font-family: "Gotham Pro Regular";
        color: #9A9A9A;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        white-space: nowrap;
      }

      span {
        color: #1B1A1F;
        font-family: 'Gotham Pro Medium';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 103.7%;
      }
    }

    &-right {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 15px;

      @media (max-width: 800px) {
        gap: 0;
      }

      &-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
        width: 100px;

        p {
          font-family: "Gotham Pro Regular";
          color: #9A9A9A;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          &.active {
            color: #CE2121;
          }
        }

        span {
          color: #1B1A1F;
          font-family: 'Gotham Pro Medium';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 103.7%;
        }
      }
    }
  }
}
</style>
